<template>
    <ol class="geoJsonLayers-list-container">
        <li
            v-for="(layer, key) in geoProjectLayers.projectDataLayers"
            :key="key"
        >
            <div class="project-layer-container">
                <div class="layer-details-container" cols="10">
                    <div class="layer-details-info">
                        <div class="layer-info-name">
                            <div class="layer-collapse-control">
                                <button
                                    v-b-toggle="'collapse-' + key"
                                    :class="'collapse-' + key"
                                    class="collapse-button"
                                >
                                    <img
                                        class="expanded"
                                        src="../../../assets/minus.svg"
                                    />
                                    <img
                                        class="collapsed"
                                        src="../../../assets/plus_sign.svg"
                                    />
                                </button>
                            </div>
                            <input
                                type="checkbox"
                                class="checkBox"
                                v-model="layer.DisplayOption"
                                @click="toggleProjectLayer(key)"
                            />

                            <span
                                v-hide="
                                    layer.Geometry &&
                                        layer.Geometry.features.length == 0
                                "
                                class="layer-name-symbol"
                                v-html="layerSymbol(layer)"
                            ></span>

                            <h5
                                class="layer-name-title"
                                :title="layer.DisplayName"
                            >
                                {{ layer.DisplayName }}
                            </h5>
                        </div>
                        <div
                            v-if="
                                layer.Geometry &&
                                    layer.Geometry.features.length > 0
                            "
                            class="layer-details-location"
                        >
                            <button
                                class="layer-zoom"
                                @click="zoomToProject(layer.Geometry)"
                            >
                                Go to Location
                            </button>
                        </div>
                    </div>
                </div>
                <b-collapse
                    :id="'collapse-' + key"
                    class="layer-details-collapse"
                >
                    <div
                        class="layer-details-controls"
                        v-if="
                            layer.Geometry && layer.Geometry.features.length > 0
                        "
                    >
                        <div cols="3" class="checkboxCol layer-control-label">
                            <label class="rowData subFont">
                                <input
                                    :disabled="!layer.ShowLabels"
                                    type="checkbox"
                                    class="checkBox mr-2"
                                    v-model="
                                        layer.Geometry.features[0].properties[
                                            layer.Geometry.labelVisibleIndex
                                        ].label
                                    "
                                    @click="toggleProjectLayerLabels(key)"
                                />
                                Label
                            </label>
                        </div>
                        <div cols="4" class=" layer-control-cluster">
                            <label class="rowData subFont">
                                <input
                                    type="checkbox"
                                    class="checkBox mr-2"
                                    :disabled="
                                        layer.Geometry.features[0].geometry
                                            .type != 'Point'
                                    "
                                    v-model="layer.Clustered"
                                    @click="toggleProjectLayerClustered(key)"
                                />Cluster
                            </label>
                        </div>
                    </div>
                    <div
                        v-else-if="!layer.visible && !layer.isFetchError"
                        class="layer-details-pending"
                    >
                        This layer is OFF. Turn the layer ON to view the
                        details.
                    </div>
                    <div
                        class="layer-details-error"
                        v-else-if="layer.isFetchError"
                    >
                        Error fetching layer. Please contact support.
                    </div>
                    <div
                        v-else-if="
                            !layer.hasOwnProperty('geomDataLoaded') &&
                                layer.visible &&
                                layer.Geometry == null
                        "
                    >
                        <div
                            class="my-3 spinner-container d-flex flex-column justify-content-center align-items-center"
                        >
                            <div
                                class="spinner-border loadingIcon mb-1"
                                role="status"
                            ></div>
                            <div class="loadingText">
                                Loading...
                            </div>
                        </div>
                    </div>

                    <div
                        class="layer-details-empty"
                        v-else-if="
                            layer.Geometry &&
                                layer.Geometry.features.length == 0
                        "
                    >
                        Coordinates not found in the data source. Please contact
                        support.
                    </div>
                </b-collapse>
            </div>
        </li>
    </ol>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { mapLayerFunctions } from '../../../utilities/mapLayerFunctions';
import { mapMarkerFunctions } from '../../../utilities/mapMarkerFunctions';
import { latLngBounds } from 'leaflet';
export default {
    name: 'GeoJsonLayers',
    data() {
        return { geoProjectLayers: { projectDataLayers: [] } };
    },
    methods: {
        async toggleProjectLayer(datasetID) {
            if (!this.geoProjectLayers.projectDataLayers[datasetID].Geometry) {
                await mapLayerFunctions.processLayer(
                    this.geoProjectLayers.projectDataLayers[datasetID],
                    this.geoProjectLayers.ProjectID
                );
            }
            this.$store.dispatch('projectLayers/updateProjectVisibility', {
                projectID: this.ProjectID,
                datasetID,
            });
            this.forceMapUpdate();
        },
        toggleProjectLayerLabels(datasetID) {
            this.$store.dispatch('projectLayers/toggleProjectLayerLabels', {
                projectID: this.ProjectID,
                datasetID,
            });
            setTimeout(() => {
                this.$store.commit('oneMap/mutateReclusterProject', true);
            }, 100);
        },

        toggleProjectLayerClustered(datasetID) {
            this.$store.dispatch(
                'projectLayers/toggleProjectLayerClusteredAction',
                { projectID: this.ProjectID, datasetID }
            );
            this.forceMapUpdate();
        },
        zoomToProject(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            // bufferPercentage is the % of the bbox that should be buffered
            let bufferPercentage = 10;
            this.$store.dispatch(
                'oneMap/setZoomToExtent',
                latLngBounds([
                    [
                        spatialFunctions.correctLatitude(
                            minY - (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            minX - (xRange * bufferPercentage) / 100
                        ),
                    ],
                    [
                        spatialFunctions.correctLatitude(
                            maxY + (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            maxX + (xRange * bufferPercentage) / 100
                        ),
                    ],
                ])
            );
        },
        layerSymbol(layer) {
            if (layer.Geometry && layer.Geometry.features.length) {
                switch (layer.Geometry.features[0].geometry.type) {
                    case 'Point':
                        return mapMarkerFunctions.getIcon(
                            layer.Symbology,
                            layer.SymbologyColor
                                ? layer.SymbologyColor
                                : layer.SymbologyColour,
                            layer.PointOutlineColor
                                ? layer.PointOutlineColor
                                : layer.PointOutlineColour
                        );

                    case 'LineString':
                    case 'MultiLineString':
                        return mapLayerFunctions.getLineIcon(
                            layer.LineColor
                                ? layer.LineColor
                                : layer.LineColour,
                            layer.LineWidth,
                            layer.LineStyle.toLowerCase() == 'dashed'
                        );
                    case 'Polygon':
                    case 'MultiPolygon':
                        return mapLayerFunctions.getPolygonIcon(
                            layer.PolygonColor
                                ? layer.PolygonColor
                                : layer.PolygonColour,
                            layer.PolygonBorderColor
                                ? layer.PolygonBorderColor
                                : layer.PolygonBorderColour,
                            layer.PolygonWidth
                        );
                }
            }
            // return a circle if no features so the v-hide is correctly offset
            return mapMarkerFunctions.getIcon('circle', '#000');
        },
        forceMapUpdate() {
            this.$parent.$parent.$parent.$parent.$refs.map.zoom++;
            this.$nextTick();
            this.$parent.$parent.$parent.$parent.$refs.map.zoom--;
        },
        populateGeoProjectLayers() {
            this.geoProjectLayers = this.$store.state.projectLayers.projects[
                this.ProjectID
            ];
            this.$store.state.projectLayers.projectLayersReady = false;
        },
    },
    created() {
        this.populateGeoProjectLayers();
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        ...mapState({
            projectLayersReady: (state) =>
                state.projectLayers.projectLayersReady,
            projectLayerAdded: (state) => state.projectLayers.projectLayerAdded,
        }),
    },
    watch: {
        ProjectID(newVal) {
            if (newVal) {
                this.geoProjectLayers = {};
            }
        },
        projectLayerAdded(newVal) {
            if (newVal == true) {
                this.populateGeoProjectLayers();
            }
        },
    },
};
</script>
<style lang="scss">
[class^='collapse'][aria-expanded='false'] .expanded {
    display: none;
}

[class^='collapse'][aria-expanded='true'] .collapsed {
    display: none;
}

.collapse-button {
    width: 30px;
    height: 21px;
    border-radius: 4px;
    background-color: white;
    border: none;
    padding-right: 5px;
    justify-self: center;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}
.collapse-button img {
    width: 17.3px;
    height: 17.3px;
}
ul {
    padding: 0;
}
.layer-zoom {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
    color: #006395;
    font-size: 16px;
    min-width: 117px;
    max-height: 1.5rem;
}
.geoJsonLayers-list-container {
    padding-left: 7px;
}
.geoJsonLayers-list-container li ul li:first-child .project-layer-container {
    border: none;
}
.layer-details-controls {
    padding-left: 28px;
    margin: 5px 0 5px 39px;
}
.layer-control-cluster {
    border-top: 1px dashed #d1d1d1;
}
.project-layer-container {
    border-top: 1px solid #d1d1d1;
    width: 100%;
}
.layer-details-collapse {
    font: normal normal normal 16px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
    border-top: 1px dashed #d1d1d1;
}
.layer-details-info {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 2px;
    align-self: center;
    width: 100%;
}
.layer-info-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.layer-details-container {
    display: flex;
    flex-flow: row nowrap;
    min-height: 56px;
    height: auto;
}
.layer-collapse-control {
    position: relative;
}
.layer-name-symbol {
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}
.layer-details-empty,
.layer-details-pending,
.layer-details-error {
    font-style: italic;
    font-size: 16px;
    margin-bottom: 6px;
}

.layer-name-title {
    max-width: 220px;
    padding-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    font: normal normal 500 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
}
@media only screen and (max-width: 1800px) {
    .layer-name-title {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.layer-details-location {
    margin-left: 60px;
}
.layer-control-label,
.layer-control-cluster {
    padding: 10px 0;
}
.layer-details-controls label {
    margin: 0 !important;
}
.layer-details-controls input {
    vertical-align: text-bottom;
}
</style>
