var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"geoJsonLayers-list-container"},_vm._l((_vm.geoProjectLayers.projectDataLayers),function(layer,key){return _c('li',{key:key},[_c('div',{staticClass:"project-layer-container"},[_c('div',{staticClass:"layer-details-container",attrs:{"cols":"10"}},[_c('div',{staticClass:"layer-details-info"},[_c('div',{staticClass:"layer-info-name"},[_c('div',{staticClass:"layer-collapse-control"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + key),expression:"'collapse-' + key"}],staticClass:"collapse-button",class:'collapse-' + key},[_c('img',{staticClass:"expanded",attrs:{"src":require("../../../assets/minus.svg")}}),_c('img',{staticClass:"collapsed",attrs:{"src":require("../../../assets/plus_sign.svg")}})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(layer.DisplayOption),expression:"layer.DisplayOption"}],staticClass:"checkBox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(layer.DisplayOption)?_vm._i(layer.DisplayOption,null)>-1:(layer.DisplayOption)},on:{"click":function($event){return _vm.toggleProjectLayer(key)},"change":function($event){var $$a=layer.DisplayOption,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(layer, "DisplayOption", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(layer, "DisplayOption", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(layer, "DisplayOption", $$c)}}}}),_c('span',{directives:[{name:"hide",rawName:"v-hide",value:(
                                layer.Geometry &&
                                    layer.Geometry.features.length == 0
                            ),expression:"\n                                layer.Geometry &&\n                                    layer.Geometry.features.length == 0\n                            "}],staticClass:"layer-name-symbol",domProps:{"innerHTML":_vm._s(_vm.layerSymbol(layer))}}),_c('h5',{staticClass:"layer-name-title",attrs:{"title":layer.DisplayName}},[_vm._v(" "+_vm._s(layer.DisplayName)+" ")])]),(
                            layer.Geometry &&
                                layer.Geometry.features.length > 0
                        )?_c('div',{staticClass:"layer-details-location"},[_c('button',{staticClass:"layer-zoom",on:{"click":function($event){return _vm.zoomToProject(layer.Geometry)}}},[_vm._v(" Go to Location ")])]):_vm._e()])]),_c('b-collapse',{staticClass:"layer-details-collapse",attrs:{"id":'collapse-' + key}},[(
                        layer.Geometry && layer.Geometry.features.length > 0
                    )?_c('div',{staticClass:"layer-details-controls"},[_c('div',{staticClass:"checkboxCol layer-control-label",attrs:{"cols":"3"}},[_c('label',{staticClass:"rowData subFont"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ].label
                                ),expression:"\n                                    layer.Geometry.features[0].properties[\n                                        layer.Geometry.labelVisibleIndex\n                                    ].label\n                                "}],staticClass:"checkBox mr-2",attrs:{"disabled":!layer.ShowLabels,"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ].label
                                )?_vm._i(
                                    layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ].label
                                ,null)>-1:(
                                    layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ].label
                                )},on:{"click":function($event){return _vm.toggleProjectLayerLabels(key)},"change":function($event){var $$a=
                                    layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ].label
                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ], "label", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ], "label", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(layer.Geometry.features[0].properties[
                                        layer.Geometry.labelVisibleIndex
                                    ], "label", $$c)}}}}),_vm._v(" Label ")])]),_c('div',{staticClass:" layer-control-cluster",attrs:{"cols":"4"}},[_c('label',{staticClass:"rowData subFont"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(layer.Clustered),expression:"layer.Clustered"}],staticClass:"checkBox mr-2",attrs:{"type":"checkbox","disabled":layer.Geometry.features[0].geometry
                                        .type != 'Point'},domProps:{"checked":Array.isArray(layer.Clustered)?_vm._i(layer.Clustered,null)>-1:(layer.Clustered)},on:{"click":function($event){return _vm.toggleProjectLayerClustered(key)},"change":function($event){var $$a=layer.Clustered,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(layer, "Clustered", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(layer, "Clustered", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(layer, "Clustered", $$c)}}}}),_vm._v("Cluster ")])])]):(!layer.visible && !layer.isFetchError)?_c('div',{staticClass:"layer-details-pending"},[_vm._v(" This layer is OFF. Turn the layer ON to view the details. ")]):(layer.isFetchError)?_c('div',{staticClass:"layer-details-error"},[_vm._v(" Error fetching layer. Please contact support. ")]):(
                        !layer.hasOwnProperty('geomDataLoaded') &&
                            layer.visible &&
                            layer.Geometry == null
                    )?_c('div',[_c('div',{staticClass:"my-3 spinner-container d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"spinner-border loadingIcon mb-1",attrs:{"role":"status"}}),_c('div',{staticClass:"loadingText"},[_vm._v(" Loading... ")])])]):(
                        layer.Geometry &&
                            layer.Geometry.features.length == 0
                    )?_c('div',{staticClass:"layer-details-empty"},[_vm._v(" Coordinates not found in the data source. Please contact support. ")]):_vm._e()])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }