<template>
    <ol class="wmsLayers-list-container">
        <li v-for="(item, idx) in treeItems" :key="idx">
            <div
                class="layer-group-container layer-group-wms"
                :class="item.parentID ? 'dashed-line' : 'solid-line'"
            >
                <span
                    v-hide="item.children.length == 0"
                    class="parent expand-collapse"
                >
                    <button
                        class="expandToggleBtn"
                        @click="toggleExpanded(item.id)"
                    >
                        <span v-if="item.isExpanded"
                            ><img
                                src="../../../assets/minus.svg"
                                class="parentExpandCollapse"
                        /></span>
                        <span v-else
                            ><img
                                src="../../../assets/plus_sign.svg"
                                class="parentExpandCollapse"
                        /></span>
                    </button>
                </span>
                <img-node :id="item.id" :zoomLevel="zoomLevel" />
            </div>
            <tree-view
                class="child"
                v-if="item.children.length > 0"
                v-show="item.isExpanded"
                :treeParent="item.id"
                :zoomLevel="zoomLevel"
            />
        </li>
    </ol>
</template>

<script>
import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import imgNode from './imgNode.vue';
import hide from '../../../directives/hide.js';

export default {
    name: 'TreeView',
    components: {
        imgNode,
    },
    props: {
        treeParent: {
            required: false,
            type: Number,
            default: 0,
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
        treeFilter: {
            required: false,
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    mounted: function() {},
    methods: {
        ...mapMutations('emergencyManagement', ['toggleExpanded']),
        logMessage() {},
        checked(e) {},
        selectNode(data, nodeName) {
            const selectedNodes = [];

            function traverse(node) {
                if (node.name === nodeName) {
                    selectedNodes.push(node);
                }

                if (node.children) {
                    for (const childId of node.children) {
                        const childNode = data.find(
                            (child) => child.id === childId
                        );
                        if (childNode) {
                            traverse(childNode);
                        }
                    }
                }
            }

            for (const rootNode of data) {
                traverse(rootNode);
            }

            return selectedNodes;
        },
    },
    computed: {
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        ...mapGetters('dreams', {
            dreamsModel: 'model',
        }),
        // sort the items in the treeItems list, ternary operation below sorts the array
        //   by the name element of the objects contained in the array
        treeItems: function() {
            var layers = this.layersStruct.filter(
                (treeItem) => treeItem.parentID == this.treeParent
            );

            if (this.treeFilter != '') {
                layers = this.selectNode(this.layersStruct, this.treeFilter);
            }

            return layers.sort(
                (a, b) =>
                    a.toc_groupOrder - b.toc_groupOrder ||
                    a.toc_layerOrder - b.toc_layerOrder ||
                    a.name.localeCompare(b.name)
            );
        },
    },
};
</script>

<style>
/* Remove default bullets */
ul {
    list-style-type: none;
}
.parent {
    left: -5px;
}
.parentExpandCollapse {
    left: 0 !important;
}
.expand-collapse {
    max-width: 220px;
    align-self: center;
}
.tocList {
    padding-left: 15px;
}
.layer-group-wms {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 36px;
    padding-bottom: 5px;
}
.check-box::before {
    content: '\2611';
    color: dodgerblue;
}
.expandToggleBtn {
    width: 21px;
    height: 21px;
    padding: 2px 2px 2px 2px;
    align-items: center;
    justify-items: center;
    border-radius: 4px;
    background-color: white;
    border: none;
    margin-right: 8px;
}
.expandToggleBtn img {
    width: 17.3px;
    height: 17.3px;
}
.layer-group-container {
    padding: 7px 0 7px 0;
}
.dashed-line {
    border-top: 1px dashed #d1d1d1;
}
.solid-line {
    border-top: 1px solid #d1d1d1;
}
.control_wrapper {
    min-width: 315px;
}
.sideBarBody .control_wrapper {
    max-width: 95% !important;
}
.wmsLayers-list-container {
    padding-left: 7px;
}
.child {
    margin-left: 40px;
}
</style>
